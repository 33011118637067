<template>
  <label :id="`${props.for}-label`" :for="props.for" class="modern-color-theme font-poppins text-sm leading-6 font-medium text-neutral-950 flex items-center" data-component-name="VSLabel">
    <slot />
    <VIcon v-if="props.tooltip" color="neutral" size="xs" name="Outline/information-circle" class="ml-1 cursor-pointer" :title="props.tooltip" />
  </label>
</template>
<script setup lang="ts">
import VIcon from '@component-library/labels/VIcon.vue';

const props = defineProps<{
  for: string
  tooltip?: string
}>()

defineOptions({
  name: 'VSLabel'
})
</script>